.addRedeemContainer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.userDetailContainer {
    margin-top: 10px;
    margin-bottom: 10px;
}

.userName {
    font-size: 20px;
    font-weight: 600;
}

.userPoint {
    font-size: 15px;
}

.emptyResultContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 10rem;
}