.formContainer {
    display: flex;
    flex: row;
    padding: 12px;
}

.fieldContainer {
    margin-right: 10px;
}

.fieldLabelStyle {
    margin-right: 5px;
}

.emptyResultContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 10rem;
}